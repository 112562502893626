<template>
  <div>
    <base-section
      id="portal"
      class="lightgrey"
      space="0"
    >
      <div style="text-align:center;">
        <div class="col s12" v-if="true">
          <div class="containerbottomwrapper">
            <div class="containercol">
              <h5>Vizion Output</h5>
            </div>
            <div class="containercol">&nbsp;</div>
            <div class="containercol">
              <v-btn
                v-if="false"
                color="green darken-1"
                text
                @click="runTest"
              >
                Run Test
              </v-btn>
            </div>
            <div class="containercol">&nbsp;</div>
            <div class="containercol">
              <v-btn
                color="green darken-1"
                text
                @click="backToVizions"
              >
                Back to Vizions
              </v-btn>
            </div>
          </div>
          <hr>
          <div class="text-center d-flex pb-4">
            spacer
          </div>
          <div class="containerwrapper">
            <div class="containercol">
              <v-img
                height="150"
                max-width="300"
                contain
                :src="vizionData.logo.downloadURL"
              ></v-img> 
            </div>
            <div class="containercol">
              &nbsp;
            </div>
            <div class="containercol">&nbsp;</div>
            <div class="containercol">
              <h2>{{ vizionData.title }} <small>({{ vizionData.CompanyName }})</small></h2> 
            </div>
            <div class="containercol">&nbsp;</div>
          </div>
        </div>
        <div v-if="hasLogo">
          
        </div>
        <v-expansion-panels
          v-model="panel"
          multiple
          v-if="vizionData && questions.length > 0"
        >
          <v-expansion-panel key="1">
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon color="primary">
                  $expand
                </v-icon>
                <v-icon color="primary" v-if="panel.length < items" @click.native.stop="openAll" ref="openBtn">
                  mdi-arrow-expand-all
                </v-icon>
                <v-icon color="primary" v-if="panel.length >= items" @click="resetPanels">
                  mdi-arrow-collapse-all
                </v-icon>
              </template>
              <h4>{{ questions[0].question }}</h4>
              </v-expansion-panel-header>
            <v-expansion-panel-content>
              <display-level :level="vizionData.level1" :levelNumber="1" :question="questions[0]" levelColor="light-blue" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel key="2">
            <v-expansion-panel-header>
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
                <h4>{{ questions[1].question }}</h4>
              </v-expansion-panel-header>
            <v-expansion-panel-content>
              <display-level :level="vizionData.level2" :levelNumber="2" :question="questions[1]" levelColor="green" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel key="3">
            <v-expansion-panel-header>
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
                <h4>{{ questions[2].question }}</h4>
              </v-expansion-panel-header>
            <v-expansion-panel-content>
              <display-level :level="vizionData.level3" :levelNumber="3" :question="questions[2]" levelColor="brown" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel key="4">
            <v-expansion-panel-header>
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
                <h4>{{ questions[3].question }}</h4>
              </v-expansion-panel-header>
            <v-expansion-panel-content>
              <display-level :level="vizionData.level4" :levelNumber="4" :question="questions[3]" levelColor="grey" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel key="5">
            <v-expansion-panel-header>
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
                <h4>{{ questions[4].question }}</h4>
              </v-expansion-panel-header>
            <v-expansion-panel-content>
              <display-level :level="vizionData.level5" :levelNumber="5" :question="questions[4]" levelColor="blue-grey" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel key="6">
            <v-expansion-panel-header>
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
                <h4>{{ questions[5].question }}</h4>
              </v-expansion-panel-header>
            <v-expansion-panel-content>
              <display-level :level="vizionData.level6" :levelNumber="6" :question="questions[5]" levelColor="indigo" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel key="7">
            <v-expansion-panel-header>
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
                <h4>{{ questions[6].question }}</h4>
              </v-expansion-panel-header>
            <v-expansion-panel-content>
              <display-level :level="vizionData.level7" :levelNumber="7" :question="questions[6]" levelColor="red" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div style="position: relative;min-height: 200px;" v-if="false"><div style="position:absolute;bottom:0;left:0;font-weight:bold;font-size:16px;">PUSHED DOWN</div></div>
        <div class="col s12" v-if="false && vizionData && questions.length > 0">
          <table border="0" cellspacing="0" cellpadding="0">
            <display-level :level="vizionData.level1" :levelNumber="1" :question="questions[0]" levelColor="light-blue" />
            <display-level :level="vizionData.level2" :levelNumber="2" :question="questions[1]" levelColor="green" />
            <display-level :level="vizionData.level3" :levelNumber="3" :question="questions[2]" levelColor="brown" />
            <display-level :level="vizionData.level4" :levelNumber="4" :question="questions[3]" levelColor="grey" />
            <display-level :level="vizionData.level5" :levelNumber="5" :question="questions[4]" levelColor="blue-grey" />
            <display-level :level="vizionData.level6" :levelNumber="6" :question="questions[5]" levelColor="indigo" />
            <display-level :level="vizionData.level7" :levelNumber="7" :question="questions[6]" levelColor="red" />
          </table>
          
        </div>
        <router-view />
      </div>
    </base-section>
  </div>
</template>

<script>
  // import * as api from '@/functions/api.js'
  import { mapGetters } from 'vuex'
  import { System } from '@/database/system'
  import { Vizions } from '@/database/deal'
  import { FirebaseResults } from '@/database'
  import $ from 'jquery'
  import DisplayLevel from '@/components/DisplayLevel'

  export default {
    name: 'SectionVizion',
    components: {
      DisplayLevel,
    },
    data: () => ({
      panel: [0],
      items: 7,
      vizionId: '',
      vizionData: {
        CompanyName: '',
        logo: {
          DocFileName: 'NoLogo.png',
          DocPath: 'NOLOGO',
          downloadURL: '/images/NoLogo.png',
        },
        title: '',
      },
      questions: [],
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      ...mapGetters({
        user: 'user',
      }),
      currentUserType () {
        return this.user.data.usertype.toLowerCase()
      },
      currentUserId () {
        return this.user.data.id
      },
      hasLogo () {
        return this.vizionData.logo.DocPath !== 'NOLOGO'
      },
    },
    mounted () {
      this.vizionId = this.$route.params.vizionid
      this.initialize()
    },
    methods: {
      initialize () {
        Vizions.findOne(this.vizionId).then(doc => {
          this.vizionData = doc.data()
          this.vizionData.id = this.vizionId
          if (!this.vizionData.logo) { 
            // Create Empty VizionLogo
            this.vizionData.logo = {
              DocFileName: 'NoLogo.png',
              DocPath: 'NOLOGO',
              downloadURL: '/images/NoLogo.png',
            }
          }
          // console.log(this.vizionData)
          System.findOne('questions').then(doc => {
            const results = doc.data().data
            // console.log(results)
            this.questions = results
            for (var i = 0; i < this.questions.length; ++i){
              this.questions[i].question = this.questions[i].question.replace('{CompanyName}', this.vizionData.CompanyName)
            }
          })
        })
      },
      findObjectByKey(array, key, value) {
        for (var i = 0; i < array.length; i++) {
            if (array[i][key] === value) {
                return array[i]
            }
        }
        return null
      },
      backToVizions () {
        this.$router.push('/vizion/dashboard').catch(err => {
          console.log('error redirecting : ', err)
        })
      },
      all () {
        this.panel = [...Array(this.items).keys()].map((k, i) => i)
      },
      openAll () {
        this.panel = [...Array(this.items).keys()].map((k, i) => i)
        this.$nextTick(() => {
          console.log(this.$refs)
          this.$refs.openBtn.$el.blur()
        })
      },
      pushPanel (nextKey) {
        this.panel.push(nextKey)
      },
      // Reset the panel
      resetPanels () {
        this.panel = [0]
      },
      runTest () {
      },
    },
  }
</script>
<style scoped>
table {
  width: 100%;
  display:table;
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: initial;
}
table, th, td {
  border: none;
  line-height: 2.5;
  font-size: 15px;
}
tr {
  border-bottom: 1px solid rgba(0,0,0,0.12);
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
  .containerwrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 4fr 1fr 4fr 1fr;
  }
  .containerbottomwrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 3fr 1fr 3fr 1fr 3fr;
  }
  .containercol {
    text-align: left;
    font-size: small;
    line-height: 34px;
  }
  .form-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .roundedAnswer {
    border-radius: 25px;
    padding: 10px;
    margin: 10px;
  }
  #myCanvas {
    border: 1px solid grey;
  }
  #svg{
    position: absolute;
    top: 0;
    left: 0;
    width: 1;
    height: 1;
  }

  .line {
    stroke-width:2px;
    stroke:rgb(0,0,0);
  }
</style>
